<script setup lang="ts">
    import heroAssets from '@/common/mockData/heroSlider.json';
    const carouselData = [
        {
            id: 'int:en:shop_product:woom-3-automagic',
            title: 'woom ORIGINAL 3 AUTOMAGIC',
            image: 'https://mediahub.woom.com/m/7ccc085439315c09/3to2-woom_3_AUTOMAGIC_Side_red_Product_2022.jpg',
            'sky-blue': 'https://mediahub.woom.com/m/14b94163f827f175/3to2-woom_3_AUTOMAGIC_Side_blue_Product_2022.jpg',
            'woom-green': 'https://mediahub.woom.com/m/28040fa8a3e95f68/3to2-woom_3_AUTOMAGIC_Automagic_Side_green_Product_2022.jpg',
            weight: {
                metric: 6.1,
                imperial: 13.4,
            },
            wheels: 16,
            min_age: 4,
            max_age: 6,
            min_height: {
                metric: 105,
                imperial: 3.4,
            },
            max_height: {
                metric: 120,
                imperial: 3.9,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-3-automagic',
                language: 'en',
            },
            price: {
                amount: '499.0',
                currencyCode: 'EUR',
                __typename: 'Money',
            },
            colors: ['woom red', 'woom green', 'sky blue'],
        },
        {
            id: 'int:en:shop_product:woom-4-rel-g-microshift',
            title: 'woom ORIGINAL 4 (microSHIFT)',
            image: 'https://mediahub.woom.com/m/3daeaa5c6d0db823/3to2-woom4_side_right_microSHIFT_red_product.jpg',
            weight: {
                metric: 7.7,
                imperial: 16.9,
            },
            wheels: 20,
            min_age: 6,
            max_age: 8,
            min_height: {
                metric: 115,
                imperial: 3.8,
            },
            max_height: {
                metric: 130,
                imperial: 4.3,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-4',
                language: 'en',
            },
            price: {
                amount: '519.0',
                currencyCode: 'EUR',
            },
        },
        {
            id: 'int:en:shop_product:woom-5-rel-g',
            title: 'woom ORIGINAL 5',
            image: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/53F6790B-5307-4777-AAED5AFD90A139AA/7C1AF374-2904-444E-AD90079585D84BC1/3to2-woom5_side_product.jpg',
            weight: {
                metric: 8.7,
                imperial: 19.3,
            },
            wheels: 24,
            min_age: 7,
            max_age: 11,
            min_height: {
                metric: 125,
                imperial: 4.1,
            },
            max_height: {
                metric: 145,
                imperial: 4.8,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-5',
                language: 'en',
            },
            price: {
                amount: '569.0',
                currencyCode: 'EUR',
            },
        },
        {
            id: 'int:en:shop_product:woom-6-rel-g',
            title: 'woom ORIGINAL 6',
            image: 'https://mediahub.woom.com/m/18039a697067658e/3to2-woom6_side_product.jpg',
            weight: {
                metric: 9.7,
                imperial: 20.9,
            },
            wheels: 26,
            min_age: 10,
            max_age: 14,
            min_height: {
                metric: 140,
                imperial: 4.6,
            },
            max_height: {
                metric: 165,
                imperial: 5.4,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-6',
                language: 'en',
            },
            price: {
                amount: '619.0',
                currencyCode: 'EUR',
            },
        },
    ];

    const carouselDataOff = [
        {
            id: 'int:en:shop_product:woom-3-automagic',
            title: 'woom OFF 4',
            image: 'https://assets-eu-01.kc-usercontent.com/608c55c4-539a-01b4-a3d1-e605ca12269f/b39c72b5-73fc-40ec-9fa1-381019ca66c0/woom4_OFF_B_pp_side.png',
            weight: {
                metric: 6.1,
                imperial: 13.4,
            },
            wheels: 16,
            min_age: 4,
            max_age: 6,
            min_height: {
                metric: 105,
                imperial: 3.4,
            },
            max_height: {
                metric: 120,
                imperial: 3.9,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-3-automagic',
                language: 'en',
            },
            price: {
                amount: '499.0',
                currencyCode: 'EUR',
                __typename: 'Money',
            },
        },
        {
            id: 'int:en:shop_product:woom-4-rel-g-microshift',
            title: 'woom OFF 5',
            image: 'https://assets-eu-01.kc-usercontent.com/608c55c4-539a-01b4-a3d1-e605ca12269f/40f3faa3-da8f-4d50-bbd7-8888c4fd0ab0/woom5_OFF_B_pp_side.png',
            weight: {
                metric: 7.7,
                imperial: 16.9,
            },
            wheels: 20,
            min_age: 6,
            max_age: 8,
            min_height: {
                metric: 115,
                imperial: 3.8,
            },
            max_height: {
                metric: 130,
                imperial: 4.3,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-4',
                language: 'en',
            },
            price: {
                amount: '519.0',
                currencyCode: 'EUR',
            },
        },
        {
            id: 'int:en:shop_product:woom-5-rel-g',
            title: 'woom OFF 6',
            image: 'https://assets-eu-01.kc-usercontent.com/608c55c4-539a-01b4-a3d1-e605ca12269f/790489f3-10dd-414b-ac48-51b0e52380e3/woom6_OFF_B_pp_side.png',
            weight: {
                metric: 8.7,
                imperial: 19.3,
            },
            wheels: 24,
            min_age: 7,
            max_age: 11,
            min_height: {
                metric: 125,
                imperial: 4.1,
            },
            max_height: {
                metric: 145,
                imperial: 4.8,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-5',
                language: 'en',
            },
            price: {
                amount: '569.0',
                currencyCode: 'EUR',
            },
        },
        {
            id: 'int:en:shop_product:woom-6-rel-g',
            title: 'woom OFF AIR 4',
            image: 'https://assets-eu-01.kc-usercontent.com/608c55c4-539a-01b4-a3d1-e605ca12269f/365dc757-c1f0-478f-928c-7909a4677d29/woom4_OFFAIR_B_pp_side.png',
            weight: {
                metric: 9.7,
                imperial: 20.9,
            },
            wheels: 26,
            min_age: 10,
            max_age: 14,
            min_height: {
                metric: 140,
                imperial: 4.6,
            },
            max_height: {
                metric: 165,
                imperial: 5.4,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-6',
                language: 'en',
            },
            price: {
                amount: '619.0',
                currencyCode: 'EUR',
            },
        },
    ];

    const carouselDataNow = [
        {
            title: 'woom NOW 4',
            image: 'https://assets-eu-01.kc-usercontent.com/608c55c4-539a-01b4-a3d1-e605ca12269f/fb5aa55b-5d02-4a52-b43a-93e6d5fde3ae/woom_NOW_4_side_product%20%281%29.png',
            weight: {
                metric: 6.1,
                imperial: 13.4,
            },
            wheels: 16,
            min_age: 4,
            max_age: 6,
            min_height: {
                metric: 105,
                imperial: 3.4,
            },
            max_height: {
                metric: 120,
                imperial: 3.9,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-3-automagic',
                language: 'en',
            },
            price: {
                amount: '499.0',
                currencyCode: 'EUR',
                __typename: 'Money',
            },
        },
        {
            title: 'woom NOW 5',
            image: 'https://assets-eu-01.kc-usercontent.com/608c55c4-539a-01b4-a3d1-e605ca12269f/ac467141-2158-4713-bf0e-9692fc72fa56/woom_NOW_5_side_product%20%281%29.png',
            weight: {
                metric: 7.7,
                imperial: 16.9,
            },
            wheels: 20,
            min_age: 6,
            max_age: 8,
            min_height: {
                metric: 115,
                imperial: 3.8,
            },
            max_height: {
                metric: 130,
                imperial: 4.3,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-4',
                language: 'en',
            },
            price: {
                amount: '519.0',
                currencyCode: 'EUR',
            },
        },
        {
            title: 'woom NOW 6',
            image: 'https://assets-eu-01.kc-usercontent.com/608c55c4-539a-01b4-a3d1-e605ca12269f/402e0d7e-aacb-472d-b9f3-d2c8d83d3c06/woom_NOW_6_side_product%20%281%29.png',
            weight: {
                metric: 8.7,
                imperial: 19.3,
            },
            wheels: 24,
            min_age: 7,
            max_age: 11,
            min_height: {
                metric: 125,
                imperial: 4.1,
            },
            max_height: {
                metric: 145,
                imperial: 4.8,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-5',
                language: 'en',
            },
            price: {
                amount: '569.0',
                currencyCode: 'EUR',
            },
        },
    ];

    const carouselDataUp = [
        {
            title: 'woom UP 5',
            image: 'https://assets-eu-01.kc-usercontent.com/608c55c4-539a-01b4-a3d1-e605ca12269f/f4da2a6b-4b65-4ef5-bdeb-c17722ec3c0f/woom_UP5-front_product.png',
            weight: {
                metric: 6.1,
                imperial: 13.4,
            },
            wheels: 16,
            min_age: 4,
            max_age: 6,
            min_height: {
                metric: 105,
                imperial: 3.4,
            },
            max_height: {
                metric: 120,
                imperial: 3.9,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-3-automagic',
                language: 'en',
            },
            price: {
                amount: '499.0',
                currencyCode: 'EUR',
                __typename: 'Money',
            },
        },
        {
            title: 'woom UP 6',
            image: 'https://assets-eu-01.kc-usercontent.com/608c55c4-539a-01b4-a3d1-e605ca12269f/4693bc62-0c6f-4de4-a305-b150510dec8f/woom_UP6-front_product.png',
            weight: {
                metric: 7.7,
                imperial: 16.9,
            },
            wheels: 20,
            min_age: 6,
            max_age: 8,
            min_height: {
                metric: 115,
                imperial: 3.8,
            },
            max_height: {
                metric: 130,
                imperial: 4.3,
            },
            url: {
                locale: 'en_INT',
                url: '/bikes/woom-4',
                language: 'en',
            },
            price: {
                amount: '519.0',
                currencyCode: 'EUR',
            },
        },
    ];
</script>
<template>
    <mol-hero-slider :data="heroAssets"></mol-hero-slider>
    <org-carousel
        :data="carouselData"
        link="woom.com"
        link-text="Learn more about woom ORIGINALS"
        title="WOOM ORIGINAL"
        description="woom ORIGINAL bikes are innovative balance and pedal bikes for kids that grow with the Rider as they improve upon their technique. Learning to ride a bike and perfecting those bike-handling skills couldnt be easier, safer or more intuitive. Who knew riding a bike could be so much fun?">
    </org-carousel>
    <org-carousel
        :data="carouselDataOff"
        title="woom OFF & woom OFF  AIR"
        text-color="text-woom-white"
        background-image="https://assets-us-01.kc-usercontent.com/f87655f0-0748-00f5-6b48-d1e83c8c570d/169a8dd3-06b1-45a5-b78e-45036fc77701/3to2-woom_off_mood_DSC4696_edit.jpg">
    </org-carousel>
    <org-carousel
        :data="carouselDataNow"
        title="woom NOW"
        background-color="AAB991">
    </org-carousel>
    <org-carousel
        :data="carouselDataUp"
        title="woom UP"
        background-color="F5F5F5">
    </org-carousel>
</template>
